import * as React from "react";
import { graphql } from "gatsby";
import TourFrame from "../general/tourFrame";

export default function Referenz({ item, index, refId }) {
  return (
    <>
      {index % 2 === 0 && (
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {item.title}
                  </h2>
                  <p
                    className="mt-4 text-lg text-gray-500"
                    dangerouslySetInnerHTML={{ __html: item.body.body }}
                  ></p>
                  <div className="mt-6">
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Ansehen
                    </a>
                  </div>
                </div>
              </div>
              {item.review && (
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p
                        className="text-base text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: "&ldquo;" + item.review.review + "&rdquo;",
                        }}
                      ></p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0"></div>
                        <div className="text-base font-medium text-gray-700">
                          {item.signature}
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              )}
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-4 h-96 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <TourFrame
                  src={item.link}
                  loading="lazy"
                  className="w-full h-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full"
                  allowfullscreen="true"
                  allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {index % 2 !== 0 && (
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {item.title}
                  </h2>
                  <p
                    className="mt-4 text-lg text-gray-500"
                    dangerouslySetInnerHTML={{ __html: item.body.body }}
                  ></p>
                  <div className="mt-6">
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Ansehen
                    </a>
                  </div>
                </div>
              </div>
              {item.review && (
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p
                        className="text-base text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: "&ldquo;" + item.review.review + "&rdquo;",
                        }}
                      ></p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0"></div>
                        <div className="text-base font-medium text-gray-700">
                          {item.signature}
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              )}
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="px-4 h-96 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <TourFrame
                  src={item.link}
                  loading="lazy"
                  className="w-full h-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full"
                  allowfullscreen="true"
                  allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
                />
                {/* <StaticImage
            placeholder="blurred"
          imgClassName="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
          src={item.image}
          alt="Customer profile user interface"
        /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export const query = graphql`
  query ReferenzQuery($myId: String) {
    contentfulReferenz(id: { eq: $myId }) {
      body {
        body
      }
      createdAt
      contentful_id
      featured
      id
      image
      link
      review {
        review
      }
      signature
      title
    }
  }
`;
