import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

class VisibilitySensorImage extends Component {
  state = {
    visibility: false,
    fullscreen: false
  }

  render() {
    return (
      <VisibilitySensor partialVisibility
        onChange={(isVisible) => {
          this.setState({visibility: isVisible})
        }}
      >
          <div className="h-full relative">
             
        <iframe src={this.state.visibility ? this.props.src : null} title={this.props.name} className="w-full h-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full" />
        </div>

        </VisibilitySensor>
    );
  }
}

export default VisibilitySensorImage;
